section {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 75vh;
    font-family: 'Montserrat', sans-serif;
    padding: 0 0 60px;
    margin: 0px auto;
    z-index: 2;
}
.section-bg {
    background-color: #f7fbfe;
}
.section-title {
    color: #008cff;
    text-align: center;
    padding-bottom: 30px;
}
.section-title h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}
.section-title p {
  margin: 15px 0 0 0;
}
.dark-mode .section-title h2 {
  color: #ffffff;
  text-shadow: #008cff 2px 2px 10px;
}

.home {
  /* background: linear-gradient(rgb(255, 255, 255),rgb(255, 255, 255),rgb(255, 255, 255),rgb(45, 255, 255)); */
  color: #363636;
}
.dark-mode .home {
  color: #ffffff;
}
.home h1 {
  color: #ff4c00;
  font-size: 1.8rem;
  font-family: 'Gruppo', sans-serif;
  font-weight: bold;
}
.home h2 {
  font-size: 3.2rem;
  padding: 10px 0;
}
.home h3 {
  font-size: 2.0rem;
  text-shadow: 2px 4px rgb(0, 140, 255);
  font-weight: bold;
  margin-bottom: 40px;
}
.home h3 strong {
  background: linear-gradient(120deg,#008cff,#ff4c00);
  color: #ffffff;
  font-size: 2.6rem;
  padding: 6px 10px;
  margin-right: -4px;
  border-radius: 12px;
  /* font-size: 3.2rem; */
}

.about {
  /* color: rgb(0, 140, 255); */
  z-index: 1;
}
.dark-mode .about {
  color: #ffffff;
}
.about img {
  width: 140px;
  box-shadow: 2px 2px 5px rgb(0, 140, 255);
  border: 2px solid rgb(66, 66, 66);
}


#work ul {
  list-style: none;
  margin: 0;
  padding: 10px;
}

@media only screen and (max-width: 768px) {
  .home h1 {
    font-size: 1.8rem;
  }
  .home h2 {
    font-size: 2.2rem;
  }
  .home h3 {
    font-size: 1.6rem;
  }
  .home h3 strong {
    margin-right: -4px;
    /* line-height: 1.5; */
  }
  .about img {
    width: 140px;
    margin-bottom: 26px;
  }
  .work, .about, .contact {
    padding: 0 0 50px;
  }
}