.cookie-banner {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.45);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    bottom: 10px;
    left: 10px;
    right: 10px;
    max-width: 660px;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    margin: 0 auto;
    padding: 16px 20px;
    border-radius: 12px;
    box-shadow: #ffffff 0 0 2px;
    z-index: 1000;
}
.dark-mode .cookie-banner {
    background: rgba(69, 69, 69, 0.45);
    box-shadow: #454545 0 0 2px;
}

p {
    margin-bottom: 0px;
    margin-right: 10px;
}
.cookie-banner__text {
    text-align: center;
}
.cookie-banner-cross {
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 6px;
    right: 6px;
    font-size: 10px;
    padding: 1px 4px;
    cursor: pointer;
}
.dark-mode .cookie-banner-cross {
    background: rgba(69, 69, 69, 0.5);
}

.cookie-banner__actions {
    display: block;
    text-align: center;
}

.cookie-banner__button {
    display: inline-block;
    width: 60px;
    text-align: center;
    padding: 2px 8px;
    border-radius: 12px;
    box-shadow: 0 0 4px rgba(69,69,69,0.65);
    cursor: pointer;
}

.cookie-banner__button--accept {
    /* margin-right: 10px; */
    /* background-color: #4CAF50; */
    background: #ffffff;
    color: #008cff;
    border: none;
    transition: all 0.3s ease;
}
.cookie-banner__button--accept:hover {
    background: #008cff;
    color: #ffffff;
    box-shadow: 0 0 4px rgba(255, 255, 255, 0.65);
}

.cookie-banner__button--reject {
    /* background-color: white; */
    border: 1px solid #ccc;
}