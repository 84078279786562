.menu-trigger {
    position: fixed;
    background: rgba(69, 69, 69, 0.45);
    background: rgba(0, 140, 255, 0.65);
    box-shadow: #454545 0 0 4px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    bottom: 20px;
    right: 22px;
    width: 26px;
    height: 26px;
    border-radius: 6px;
    cursor: pointer;
    /* padding: 10px; */
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
}
.dark-mode .menu-trigger {
    background: rgba(0, 140, 255, 0.65);
    box-shadow: #f7fbfe 0 0 4px;
}
.menu-trigger::before,
.menu-trigger::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 4px;
    border-radius: 2px;
    background: #ffffff;
    transition: all 0.3s ease;
}

.menu-trigger:hover {
    box-shadow: #f7fbfe 0 0 4px;
}
.menu-trigger.active {
    background: linear-gradient(45deg,#008cff,#454545);
    /* background: rgba(0, 140, 255, 1); */
    box-shadow: #f7fbfe 0 0 4px;
}
.menu-trigger::before {
    transform: rotate(90deg);
}

.menu-trigger.active::before {
    transform: rotate(45deg);
}

.menu-trigger.active::after {
    transform: rotate(-45deg);
}

.corner-menu {
    position: fixed;
    /* background: rgba(225, 255, 255, 0.45);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px); */
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    bottom: 52px;
    right: 14px;
    margin: 0;
    padding: 8px;
    border-radius: 15px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    transition: all 0.3s ease;
    transform: scale(0.8);
    opacity: 0;
    visibility: hidden;
    z-index: 999;
}

.corner-menu.active {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
}


a.footIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #454545;
    color: #ffffff;
    width: 28px;
    height: 28px;
    cursor: pointer;
    /* padding: 4px 6px 8px; */
    border-radius: 10px;
    transition: all 0.3s ease;
}
.dark-mode a.footIcon {
    background: #ffffff;
    color: #008cff;
}

a.footIcon:hover {
    color: #ffffff;
    box-shadow: #6b6b6b 0 0 6px;
}
.dark-mode a.footIcon:hover {
    color: #008cff;
    box-shadow: #f7fbfe 0 0 6px;
}

.wa a.footIcon {
    background: #25D366;
    color: #ffffff;
    margin-bottom: 6px;
}
.dark-mode .wa a.footIcon:hover {
    color: #ffffff;
    box-shadow: #f7fbfe 0 0 6px;
}


span.footIconText {
    position: absolute;
    /* background: #008cff; */
    right: 100%;
    top: 70%;
    font-size: 12px;
    line-height: 10px;
    text-align: right;
    /* padding: 12px; */
    padding: 2px 6px 0px;
    pointer-events: none;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.3s ease;
    white-space: nowrap;
}
.menu-item:hover span.footIconText {
    opacity: 1;
}

.wa span.footIconText {
    top: 25%;
}
