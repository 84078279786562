@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gruppo&display=swap');

body {
  background: #ffffff;
  color: #000000;
  scroll-behavior: smooth;
  font-family: "Gruppo", sans-serif;
  font-weight: 400;
  font-style: normal;
}
body.dark-mode {
  background: #222222;
  color: white;
}
p, li {
  color: #454545;
}
.dark-mode p, .dark-mode li {
  color: #ffffff;
}
a {
  color: #008cff;
  text-decoration: none;
}
.dark-mode a {
  color: #f7fbfe;
}
a.btn {
  /* background: linear-gradient(0deg,#454545,#008cff); */
  background: transparent;
  font-weight: bold;
  padding: 6px 10px;
  border-radius: 20px;
  border: 1px solid #454545;
  box-shadow: 0px 2px #454545;
  transition: all 0.15s ease-in-out;
}
.dark-mode a.btn {
  background: linear-gradient(0deg,#222222,#454545);
  color: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 2px #ffffff;
}
a.btn:hover {
  /* background: linear-gradient(90deg,#008cff,#008cff); */
  /* color: #008cff; */
  border: 1px solid #008cff;
  box-shadow: 0px 0px #454545;
}

.b-dark {
  background: rgba(0, 0, 0, 0);
  padding: 16px 0;
  transition: 150ms all ease-in-out;
  -webkit-transition: 150ms all ease-in-out;
  -moz-transition: 150ms all ease-in-out;
  -ms-transition: 150ms all ease-in-out;
  -o-transition: 150ms all ease-in-out;
}
.b-dark.scrolled {
  background: rgba(225, 255, 255, 0.45);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  -moz-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-box-shadow: 0px 0px 4px #adadad;
          box-shadow: 0px 0px 4px #adadad;
}
.b-dark.scrolled .subtitle {
  display: none;
}

.toggler-btn span{
  background-color: #000;
}
.navbar .navbar-collapse {
  flex-grow: 0;
}

a.navbar-brand {
  width: 160px;
  height: 46px;
  position: relative;
  color: #ff4c00;
  font-weight: bold;
  z-index: 3;
}
body a.navbar-brand .logoColor {
  fill: #454545;
}
body.dark-mode a.navbar-brand .logoColor {
  fill: #ffffff;
}
a.navbar-brand img {
  height: 45px;
}
a.navbar-brand h6 {
  color: rgb(0, 140, 255);
}

a.navbar-brand:hover {
  color: rgb(0, 140, 255);
}
.top-nav-collapse {
  background-color: rgba(225, 255, 255, 0.65);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

/* Hamburger styling */

.navbar-container {
  display: flex;
  justify-content: space-between;
  padding-top: 45px;
  padding-bottom: 45px;
}

.div1 {
  cursor: pointer;
  width: 36px;
  height: min-content;
  position: relative;
  z-index: 3;
  /* display: none; */
  /* padding: 12.5px 10px; */
  margin: 11px 12px 0 32px;
}

.sub1, .sub2, .sub3 {
  width: 32px;
  height: 5px;
  margin-bottom: 5px;
  background: #454545;
  border-radius: 5px;
  transition: all .2s ease-in-out;
}
body.dark-mode .sub1, body.dark-mode .sub2, .sub3 {
  background: #ffffff;
}
.sub3 {
  width: 36px;
}
.sub3 {
  background: #ff4c00;
}

.div1:hover .sub1, .div1:hover .sub2 {
  background: linear-gradient(90deg,#008cff,#ff4c00);
  background: #008cff;
  width: 36px;
}
.dark-mode .div1:hover .sub1, .dark-mode .div1:hover .sub2 {
  background: #ffffff;
}

.div1.cross .sub2, .dark-mode .div1.cross .sub2{
  background: transparent;
  box-shadow: none;
  transition: all 0.2s ease-in-out;
}

.div1.cross .sub1 {
  width: 36px;
  transform: rotate(45deg) translate(7px, 7px);
}
.div1.cross:hover .sub1 {
  background: #454545;
}
body.dark-mode .div1.cross:hover .sub1 {
  background: #ffffff;
}

.div1.cross .sub3 {
  width: 36px;
  box-shadow: 0px 1.5px 0px 1.5px rgba(247, 251, 254,0.65);
  transform: rotate(-45deg) translate(7px, -7px);
}
body.dark-mode .div1.cross .sub3 {
  box-shadow: 0px 1.5px 0px 1.5px rgba(69, 69, 69,0.65);
}

.nav-ham a {
  text-decoration: none;
}

.nav-ham ul {
  list-style: none;
  /* padding: 0.65rem 0 0 0; */
  margin: 0;
}

.nav-ham ul li {
  display: inline-block;
}
.nav-ham ul li a {
  display: block;
  /* background: #adadad; */
  /* height: 51px; */
  color: #454545;
  font-weight: bold;
  text-transform: uppercase;
  padding: 12px 0;
  margin-left: 32px;
  border-bottom: solid 0px #ff4c00;
}
.dark-mode .nav-ham ul li a {
  color: #ffffff;
}
.nav-ham li a.activ, .nav-ham li a:hover {
  color: #454545;
  border-bottom: solid 2px #ff4c00;
  transition: all 0.2s;
}
.dark-mode .nav-ham li a.activ, .dark-mode .nav-ham li a:hover {
  color: #ffffff;
}

.nav-ham ul li a.btn {
  padding: 12.5px 10px;
}
.nav-ham ul li a.btn:hover {
  color: #ffffff;
  border-bottom: solid 0px #ff4c00;
}


.nav-ham a.button, .book {
  background: rgb(0, 140, 255);
  color: rgb(255, 255, 255);
  font-weight: bold;
  padding: 8px;
  border-radius: 5px;
  box-shadow: 2px 2px rgb(0, 90, 160);
  transition: all 0.2s;
}
.book {
  font-size: 1rem;
  text-decoration: none;
  color: rgb(20, 150, 255);
  background: #ffffff;
  box-shadow: 2px 2px rgb(0, 46, 82);
}
.nav-ham a.button:hover {
  color: rgb(230, 230, 230);
  box-shadow: 1px 1px rgb(54, 54, 54);
  background: rgb(54, 54, 54);
}

.sidebar {
  background-color: rgba(225, 255, 255, 0.65);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.45s ease-out;
}
body.dark-mode .sidebar {
  background-color: rgba(69, 69, 69, 0.65);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  color: #ffffff;
}
.sidebar.active {
  /* right: -104%; */
  opacity: 1;
  visibility: visible;
  transition: all 0.45s;
  /* box-shadow: 5px 6px 10px #454545; */
}
.sidebar .container {
  /* bottom: 120px; */
  position: relative;
  /* max-height: 250px; */
  /* overflow-y: scroll; */
}
.sidebar .container .row {
  margin: 0;
}
.sidebar .social-link div {
  width: 50%;
  margin: auto;
  color: #ffffff;
}
.show {
  display: none;
}
.sidebar-list {
  list-style-type: none;
  padding: 0;
  /* margin-top: 5rem; */
  text-align: center;
}
.sidebar-list li a{
  display: block;
  color: #454545;
  width: 100%;
  padding: 16px 22px;
  font-size: 2.6rem;
  font-weight: bold;
  text-decoration: none;
}
.dark-mode .sidebar-list li a {
  color: #ffffff;
}
.sidebar-list li a:hover, .sidebar-list li a.activ {
  background: #454545;
  color: #f7fbfe;
}
.dark-mode .sidebar-list li a:hover, .dark-mode .sidebar-list li a.activ {
  background: #f7fbfe;
  color: #454545;
}
/* Hamburger styling end */


/* ----------------- Mode Switcher ----------------------- */

#modeBtn {
  position: fixed;
  z-index: 10;
  right: 50%;
  bottom: 18px;
}
.modeBtn-cont {
  position: relative;
  display: inline-block;
}

.modeBtn {
  background: #454545;
  cursor: pointer;
  padding: 4px 6px 8px;
  border-radius: 10px;
  transition: all 0.3s ease;
}
.modeBtnText {
  position: absolute;
  /* background: rgba(225, 255, 255, 0.45);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px); */
  left: 100%;
  top: 50%;
  font-size: 12px;
  line-height: 10px;
  text-align: left;
  padding: 2px 6px 0px;
  pointer-events: none;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  white-space: nowrap;
}
.modeBtn-cont:hover .modeBtnText {
  opacity: 1;
}

.modeBtn svg {
  color: #ffffff;
}
.modeBtn:hover {
  box-shadow: #f7fbfe 0 0 4px;
}
.dark-mode .modeBtn {
  background: #ffffff;
}
.dark-mode .modeBtn svg {
  color: #008cff;
}
.dark-mode .modeBtn:hover {
  box-shadow: #454545 0 0 4px;
}

/* ----------------- Mode Switcher End ----------------------- */

@media only screen and (max-width: 1000px) {
  .nav-ham ul {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .navbar-container {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .nav-ham ul {
    display: none;
  }
  .div1 {
    display: block;
  }
  .sidebar .container {
    padding: 0;
  }
  #modeBtn {
    right: 20px;
    bottom: 32px;
  }
  .modeBtnText {
    left: auto;
    right: 100%;
    text-align: right;
  }
  .hidden {
    display: none;
  }
  .show {
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .home h1 {
    font-size: 2.0rem;
  }
  .home h2 {
    font-size: 2.2rem;
  }
  .home h2 strong {
	font-size: 2.5rem;
  }
  .home h3 {
    font-size: 1.2rem;
  }
  .nav-ham ul {
    display: none;
  }
  .div1 {
    display: block;
  }
  .hidden {
    display: none;
  }
  .show {
    display: block;
  }
}
@media only screen and (max-height: 620px) {
  /* .home {
    padding-top: 8rem;
  } */
  .home h1 {
    font-size: 1.6rem;
  }
  .home h2 {
    font-size: 1.8rem;
  }
  .home h2 strong {
	font-size: 2.0rem;
  }
  .home h3 {
    font-size: 0.8rem;
  }
}
@media only screen and (max-height: 600px) {
	/* .home {
	  padding-top: 15rem;
	} */
}
