.services-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    padding: 20px;
}
.service-card {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    /* width: 250px; */
    /* height: 250px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}
.dark-mode .service-card {
    background: #454545;
    
}

.service-icon {
    font-size: 48px;
    color: #007bff;
    margin-bottom: 16px;
}
.dark-mode .service-icon {}

h3 {
    margin: 0 0 10px;
    font-size: 20px;
}

.dark-mode .service-card .desc, .service-card .desc {
    margin: 0;
    color: #555;
}

