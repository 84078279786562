footer {
  position: relative;
  background: rgba(255,76,0,1.0);
  color: #ffffff;
  font-size: 16px;
  /* top: 85px; */
  padding: 20px 0;
  z-index: 2;
}

footer a {
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
}
footer a:hover {
  color: #ffffff;
  text-shadow: #000000 2px 2px 0px;
}

.waves {
  position:relative;
  width: 100%;
  height:15vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:60px;
  max-height:60px;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:32px;
    min-height:32px;
    max-height:32px;
  }
}

@media only screen and (max-width: 768px) {
  footer {
    font-size: 12px;
    /* top: 60px; */
    /* padding: 10px 0 6px 0; */
  }
  footer a {
    font-size: 14px;
  }
}